<template id="scan">
  <div class="container">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row text-end mt-2">
        <div class="col-3 offset-8">
          <select class="form-select" v-model="marktID" @change="setMarktID">
            <option value="0" disabled>Selecteer een markt</option>
            <option v-for="markt in allMarkten" :key="markt.ID" :value="markt.ID">
              {{ markt.naam }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="marktID > 0">
        <h1>{{ markt.naam }}</h1>
        <form m v-on:submit.prevent="search" v-if="false">
          <div class="row">
            <div class="col-8 big-search">
              <div class="input-group mb-1">
                <input type="text" class="form-control" ref="search" v-model="barcode" :disabled="searching" v-focus />
              </div>
            </div>
            <div class="col-4">
              <button type="button" class="btn btn-lg btn-success" v-on:click="search()" :disabled="searching">
                <i class="fas fa-search"></i><span class="d-none d-md-inline"> Zoeken</span>
              </button>
            </div>
          </div>
        </form>

        <hr />

        <div class="col-12 status" v-if="status == 'searching'" style="background-color: #548df7;">
          <div class="spinner-border text-light" role="status"></div>
        </div>
        <div class="col-12 status" v-if="status == 'scan'" style="background-color: #8bc3f4;">
          <div class="row">
            <div class="col-12 text-center">
              <i class="far fa-barcode-read fa-4x"></i>
            </div>
            <div class="col-12 text-center mt-3">
              <h3>Scan Barcode</h3>
            </div>
          </div>
        </div>
        <div class="col-12 status" v-if="status == 'error'" style="background-color: #c92112;">
          <div class="row">
            <div class="col-12 text-center">
              <i class="fas fa-times text-light fa-4x"></i>
            </div>
            <div class="col-12 text-center text-light mt-3">
              <h3>{{ statusNotice }}</h3>
            </div>
          </div>
        </div>
        <div class="col-12 status" v-if="status == 'warning'" style="background-color: #fcbd35;">
          <div class="row">
            <div class="col-12 text-center">
              <i class="fas fa-exclamation-circle text-light fa-4x"></i>
            </div>
            <div class="col-12 text-center text-light mt-3">
              <h3>{{ statusNotice }}</h3>
            </div>
          </div>
        </div>
        <div class="col-12 status" v-if="status == 'ok'" style="background-color: #31c421;">
          <div class="row">
            <div class="col-12 text-center">
              <i class="fas fa-check text-light fa-4x"></i>
            </div>
            <div class="col-12 text-center text-light mt-3">
              <h3>OK</h3>
            </div>
          </div>
        </div>

        <div class="row" v-if="barcodeData">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">MVM{{ barcodeData.data.mvmnummer }}</h4>

                <h5 class="card-title mt-3">Kinderen</h5>
                <ul class="list-group list-group-flush">
                  <li class="list-group list-group-item" v-for="kind in barcodeData.data.kinderen" :key="kind.naam">{{
      kind.naam }} - {{ kind.geslacht }} - {{ kind.leeftijd }} jaar</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marktenService } from "../../_services/markten.service";

export default {
  template: "#search",
  components: {},
  data: function () {
    return {
      status: "scan",
      statusNotice: "",
      loading: false,
      barcode: "",
      searching: false,
      dxuAbort: null,
      marktID: 0,
      allMarkten: [],
      markt: {},
      barcodeKey: "",
      barcodeData: null,
    };
  },

  mounted() {
    // Attach keydown event listener to window when component mounts
    window.addEventListener('keydown', this.handleKeyPress);
    this.handleDXU();
  },
  beforeUnmount() {
    // Remove keydown event listener before the component is destroyed
    window.removeEventListener('keydown', this.handleKeyPress);
    if (this.dxuAbort) {
      this.dxuAbort.abort();
    }
  },

  methods: {
    handleDXU: async function () {
      const dxuResponse = await fetch("http://127.0.0.1:8080");
      if ((await dxuResponse.text()).toLowerCase().indexOf("memor") === -1) {
        return
      }

      this.dxuAbort = new AbortController();

      // keep fetchhing "/scan?action=read_listener" until aborted
      for (; ;) {
        try {
        const scan = await fetch("http://127.0.0.1:8080/scan?action=read_listener", { signal: this.dxuAbort.signal });
        const dxuText = await scan.text();

        if (dxuText.length > 0) {
          this.barcode = dxuText;
          this.search();
        }
        if (this.dxuAbort.signal.aborted) {
          break;
        }
        } catch (e) {
          console.log(e);
        }
      }
    },
    stringToArrayBuffer: function (string) {
      const encoder = new TextEncoder();
      return encoder.encode(string).buffer;
    },
    handleKeyPress: function (event) {
      // check if the search input is focused
      if (document.activeElement === this.$refs.search) {
        return;
      }
      event.preventDefault();
      if (event.key.length === 1 || parseInt(event.key, 10) >= 0) {
        this.barcode += event.key;
      }
      // handle backspace
      if (event.key === "Backspace") {
        this.barcode = this.barcode.slice(0, -1);
      }
      // handle enter
      if (event.key === "Enter") {
        this.search();
      }

    },

    search: async function () {
      this.status = "searching";
      this.statusNotice = "";
      this.barcodeData = null;

      try {
        const base64ToArrayBuffer = (base64) => {
          const binaryString = window.atob(base64);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          return bytes.buffer;
        };

        const arrayBufferToString = (buffer) => {
          return new TextDecoder().decode(buffer);
        };

        const ciphertextBuffer = base64ToArrayBuffer(this.barcode);
        const nonceSize = 12; // This should match the size used during encryption
        const nonce = ciphertextBuffer.slice(0, nonceSize);
        const actualCiphertext = ciphertextBuffer.slice(nonceSize);

        const keyBuffer = new TextEncoder().encode(this.barcodeKey);


        const cryptoKey = await window.crypto.subtle.importKey(
          "raw",
          keyBuffer,
          { name: "AES-GCM" },
          false,
          ["decrypt"]
        );

        const decryptedBuffer = await window.crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: nonce,
          },
          cryptoKey,
          actualCiphertext
        );

        const decryptedText = arrayBufferToString(decryptedBuffer);
        this.barcodeData = JSON.parse(decryptedText);

        if (this.barcodeData.data.markt_id !== this.marktID) {
          this.status = "error";
          this.statusNotice = "Barcode is niet voor deze markt";
          return;
        }

        const aanwezigheid = await marktenService.scanAanwezig(this.marktID, {
          barcode: this.barcode
        })

        if (aanwezigheid.status === "already present") {
          const scanTime = new Date(aanwezigheid.aanwezigheid.tijd);
          // warning is scan time is less than 5 minutes ago
          if (Date.now() - scanTime.getTime() < 5 * 60 * 1000) {
            this.status = "warning";
          } else {
            this.status = "error";
          }
          this.statusNotice = `Barcode is al gescand op ${scanTime.toLocaleString()}`;
          return;
        }

        this.status = "ok";

      } catch (error) {
        this.status = "error";
        this.statusNotice = "Barcode is niet geldig";
        this.barcode = "";
        console.log(error);
      }

      this.barcode = "";
    },

    setMarktID: function (e) {
      const id = e.target.value;
      this.marktID = parseInt(id, 10);
      this.markt = this.allMarkten.find(e => e.id == id);
    },

    loadData: async function () {
      this.loading = true;
      this.barcodeKey = (await marktenService.getBarcodeKey()).key;
      this.allMarkten = await marktenService.getCurrent();
      if (this.allMarkten.length === 1) {
        this.marktID = this.allMarkten[0].ID;
        this.markt = this.allMarkten[0];
      }
      this.loading = false;
    },
  },

  created: function () {
    this.loadData();
  },
};

</script>

<style scoped>
.status {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
