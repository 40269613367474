import Vue from "vue";
import { createApp, configureCompat } from "vue";
import App from "./App.vue";
import router from "./router";
import Simplert from "vue2-simplert-plugin";
import Paginate from "vuejs-paginate";
import VueGoodTablePlugin from "vue-good-table-next";

import { authService } from "@/_services/auth.service";

import "./scss/mvm.scss";
import "vue-good-table-next/dist/vue-good-table-next.css";

require("vue2-simplert-plugin/dist/vue2-simplert-plugin.css");

Vue.use(Simplert);
Vue.use(VueGoodTablePlugin);
Vue.component("paginate", Paginate);
Vue.config.productionTip = false;

configureCompat({ RENDER_FUNCTION: false });

Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
  },
});

Vue.directive("disable-all", {
  // When all the children of the parent component have been updated
  componentUpdated: function (el, binding) {
    if (!binding.value) return;
    const tags = ["input", "button", "textarea", "select"];
    tags.forEach((tagName) => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });
  },
});

let app = createApp(App).use(router).mount("#app");

Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
};

const authCheck = async function () {
  try {
    console.log("Checking session");
    await authService.check();
  } catch (e) {
    if (app.$router.path != "/login") {
      await app.$router.push("/login");
    }
  }
};

authCheck();

setInterval(authCheck, 60 * 1000);
